.check {
  position: relative;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 2px;
  appearance: none;
  background-color: #bbb;
  transition: all .3s;
 }
 
 .check::before {
  content: '';
  position: absolute;
  border: solid #fff;
  display: block;
  width: .3em;
  height: .6em;
  border-width: 0 .2em .2em 0;
  z-index: 1;
  opacity: 0;
  right: calc(50% - .2em);
  top: calc(50% - .3em);
  transform: rotate(0deg);
  transition: all .3s;
  transform-origin: center center;
 }
 
 .check:checked {
  animation: a .3s ease-in forwards;
  background-color: rgb(120, 190, 120);
 }
 
 .check:checked::before {
  opacity: 1;
  transform: rotate(405deg);
 }
 
 @keyframes a {
  0% {
   opacity: 1;
   transform: scale(1) rotateY(0deg);
  }
 
  50% {
   opacity: 0;
   transform: scale(.8) rotateY(180deg);
  }
 
  100% {
   opacity: 1;
   transform: scale(1) rotateY(360deg);
  }
 }