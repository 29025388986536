
@media screen and (max-width: 550px) {

    .container_recover_app{
        width: 294px;
        height: 386px;
        font-size: 13px;
    }

    .title{
        font-size: 23px;
    }
    .container_img{
        margin-top: -9px;
        width: 113px;
        height: 103px;
    }
}

@media screen and (max-width: 950px) {

    .container_recover_app{

        width: 352px;
        height: 504px;
        font-size: 13px;
      
    }
    .container_img{
        width: 113px;
        height: 91px;
    }
    .title{
        font-size: 23px;
    }
}


@media screen and (min-width: 1424px) {
    .container_recover_app{
        width: 31%;
        height: 65%;
    
    }

    .input{
        font-size: 1.75rem;
        height: 2.50rem;
    }

    label{
        font-size: 1.75rem;
    }

    .container_img{
       
        width: 30%;
        height: 20%;
   
    }

    .title{
        font-size: 37px;
    }
    .btn_cambio{
        padding: 6px 67px;
        font-size: 1.50rem;
    }



}