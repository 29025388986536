@tailwind base;
@tailwind components;
@tailwind utilities;

*{
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button { 
	-webkit-appearance: none;
} 

svg{
    stroke-width: 1.5; 
    stroke-linecap: round;
    stroke-linejoin: round;
}

.container-scroll::-webkit-scrollbar {
    width: 8px;    
    height: 8px;   
  }
  
  .container-scroll::-webkit-scrollbar-thumb {
    background: #7f7f7f;
    border-radius: 4px;
  }
  
  .container-scroll::-webkit-scrollbar-thumb:hover {
    background: #b3b3b3;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  }
  
  .container-scroll::-webkit-scrollbar-thumb:active {
    background-color: #999999;
  }
  
  .container-scroll::-webkit-scrollbar-track {
    background: #adb5bd;
    border-radius: 4px;
  }
  
  .container-scroll::-webkit-scrollbar-track:hover,
  .container-scroll::-webkit-scrollbar-track:active {
  background: #757575;
  }
  