@media screen and (max-width: 550px) {

    .container_remember_app{
        width: 332px;
        height: 333px;
        font-size: 13px;
    }

    .title_contaseña{
        font-size: 23px;
    }

    .sub_title{
        font-size: 15px;
    }
    .container_img_remember{
        margin-top: -9px;
        width: 113px;
        height: 103px;
    }

}

@media screen and (max-width: 950px) {

    .container_remember_app{

        width: 352px;
        height: 398px;
        font-size: 13px;
      
    }
    .container_img_remember{
        width: 113px;
        height: 91px;
    }
    .title_contaseña{
        font-size: 23px;
    }
}


@media screen and (min-width: 1424px) {
    .container_remember_app{
        width: 31%;
        height: 59%;
    }

    .input_password{
        font-size: 1.25rem;
        height: 2.50rem;
    }

    label{
        font-size: 1.75rem;
    }

    .container_img_remember{
       
        width: 30%;
        height: 20%;
   
    }

    .btn_volver{
        padding: 6px 67px;
        font-size: 1.50rem;
    }

    .btn_enviar{
        padding: 6px 67px;
        font-size: 1.50rem;
    }

    .sub_title{
        font-size: 22px;
    }

    .title_contaseña{
        font-size: 37px;
    }
   
}